// MarqueeComponent.js
import React from "react";
import "../assets/scss/3-elements/_marquee.scss";

const MarqueeComponent = () => {
  return (
    <div className="marquee">
      <p>
        <a
          href={`${process.env.PUBLIC_URL}/uploads/SELECTED_STUDENTS_2024_2025.pdf`}
        >
          Download List of Selected Students Certificate and Diploma Academic
          Year 2024/2025
        </a>
      </p>
      {/* <p> */}
      {/*   Application for Certificate and Diploma Academic Year 2024/2025 is Now */}
      {/*   Open */}
      {/* </p> */}
    </div>
  );
};

export default MarqueeComponent;
