import React, { Component } from "react";
import { Link } from "react-router-dom";

class MobileMenu extends Component {
  state = {
    active: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  componentDidMount() {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>",
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
  }

  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div
          className={`offcanvas-mobile-menu ${this.state.active ? "active" : ""} `}
          id="mobile-menu-overlay"
        >
          {/* <a */}
          {/*   href="#/" */}
          {/*   className="offcanvas-menu-close" */}
          {/*   id="mobile-menu-close-trigger" */}
          {/*   onClick={this.toggleMobileMenu} */}
          {/* > */}
          {/*   <i className="ion-android-close" /> */}
          {/* </a> */}
          <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
              <div className="offcanvas-mobile-search-area">
                <form action="#">
                  <input type="search" placeholder="Search ..." />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
              <nav className="offcanvas-navigation" id="offcanvas-navigation">
                <ul>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      onClick={this.toggleMobileMenu}
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/vision`}>ABOUT</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/vision`}
                          onClick={this.toggleMobileMenu}
                        >
                          Vision, Mission, Core Values
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/background`}
                          onClick={this.toggleMobileMenu}
                        >
                          History and Major Roles
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/orgonogram`}
                          onClick={this.toggleMobileMenu}
                        >
                          Organization Structure
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/principal`}
                          onClick={this.toggleMobileMenu}
                        >
                          Message from the Principal
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/leaders`}
                          onClick={this.toggleMobileMenu}
                        >
                          FTI Top Leadership
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      ADMISSION
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/programs`}
                          onClick={this.toggleMobileMenu}
                        >
                          Programs Offered
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/how-to-apply`}
                          onClick={this.toggleMobileMenu}
                        >
                          How to Apply
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/online`}
                          onClick={this.toggleMobileMenu}
                        >
                          Online Application
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      ACADEMICS
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/departments`}
                          onClick={this.toggleMobileMenu}
                        >
                          Academic Departments
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/short-courses`}
                          onClick={this.toggleMobileMenu}
                        >
                          Short Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://saris.maliasili.go.tz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SARIS
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/students`}
                          onClick={this.toggleMobileMenu}
                        >
                          Students' Welfare
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      FACILITIES
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/projects`}
                          onClick={this.toggleMobileMenu}
                        >
                          ICT Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/project-details`}
                          onClick={this.toggleMobileMenu}
                        >
                          Library
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/project-details`}
                          onClick={this.toggleMobileMenu}
                        >
                          Apiary
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/project-details`}
                          onClick={this.toggleMobileMenu}
                        >
                          Sawmill
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/project-details`}
                          onClick={this.toggleMobileMenu}
                        >
                          Tree Nursery
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/project-details`}
                          onClick={this.toggleMobileMenu}
                        >
                          Training Forest
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/news-left-sidebar`}
                      onClick={this.toggleMobileMenu}
                    >
                      {" "}
                      NEWS AND EVENTS
                    </Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link
                      to={`${process.env.PUBLIC_URL}/news-left-sidebar`}
                      onClick={this.toggleMobileMenu}
                    >
                      GALLERY
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/news-left-sidebar`}
                          onClick={this.toggleMobileMenu}
                        >
                          All
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
                          onClick={this.toggleMobileMenu}
                        >
                          Image Gallery
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/news-details-left-sidebar`}
                          onClick={this.toggleMobileMenu}
                        >
                          Video Gallery
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/contact-us`}
                      onClick={this.toggleMobileMenu}
                    >
                      CONTACT
                    </Link>{" "}
                  </li>
                </ul>
              </nav>
              <div className="offcanvas-widget-area">
                <div className="off-canvas-contact-widget">
                  <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                      <li>
                        <i className="ion-android-phone-portrait" />{" "}
                        <a href="tel://0685900989">(+255) 685 900 989 </a>
                      </li>
                      <li>
                        <i className="ion-android-mail" />{" "}
                        <a href="mailto:info@yourdomain.com">info@fti.ac.tz</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                <div className="off-canvas-widget-social">
                  <a href="#/" title="Facebook">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="#/" title="Twitter">
                    <i className="fa fa-twitter" />
                  </a>
                  <a href="#/" title="Instagram">
                    <i className="fa fa-instagram" />
                  </a>
                </div>
                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;
