import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  programs: string,
  level: string,
  duration: string,
  requirements: string,
  fees: string,
) {
  return { programs, level, duration, requirements, fees };
}

const rows = [
  createData('Basic Technician Certificate in Forestry', 'NTA-4', '1', 
    'The applicant must have an Ordinary Certificate of Secondary Education (OCSE) or equivalent with at least four (4) credits (D and above passes) in either of the two of the following subjects: biology, geography, chemistry, physics, or agriculture, and another two in non-religious subjects. Mathematics and English are added advantages for applicants.', '1,190,000',),
  createData('Technician Certificate in Forestry', 'NTA-5',  '1',
    'The applicant must have a Basic Technician Certificate in Forestry (NTA Level 4) or equivalent, or have an Advanced Level Certificate of Secondary Education (ACSE) or its equivalent with a principal pass in Biology, Chemistry, Geography, Agriculture Science, or Physics, and a subsidiary pass in any subject.', '1,250,000',),
  createData('Ordinary Diploma in Forestry', 'NTA-6', '1', 
    'The applicant must have a Technician Certificate in Forestry (NTA Level 5) or its equivalent from recognized institutions by NACTVET.', '1,600,000',),
  createData('Ordinary Diploma in Urban Forestry and Landscaping', 'NTA-6', '1', 
    'The applicant must have a Technician Certificate in Forestry (NTA Level 5) or its equivalent from recognized institutions by NACTVET.', '1,700,000',),
  createData('Ordinary Diploma in Geoinformatics For Natural Resources Management', 'NTA-6', '1', 
    'The applicant must have a Technician Certificate in Forestry (NTA Level 5) or its equivalent from recognized institutions by NACTVET.', '1,870,000',),
];

export default function ProgramsOffered() {
  return (
    <TableContainer component={Paper}>
      <Table className="table table-responsive table-bordered table-striped border-dark table-hover">        <TableHead style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>
          <TableRow style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>
            <StyledTableCell style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>Programme</StyledTableCell>
            <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>Level</StyledTableCell>
            <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>Duration&nbsp;(Year)</StyledTableCell>
            <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>Entry Requirements</StyledTableCell>
            <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>Tuition Fee&nbsp;(Tsh)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.programs}>
              <StyledTableCell component="th" scope="row">
                {row.programs}
              </StyledTableCell>
              <StyledTableCell align="right">{row.level}</StyledTableCell>
              <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'center', }}>{row.duration}</StyledTableCell>
              <StyledTableCell align="right" style={{ wordWrap: 'break-word', whiteSpace: 'normal', textAlign:'justify', }}>{row.requirements}</StyledTableCell>
              <StyledTableCell align="right">{row.fees}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

