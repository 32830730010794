import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  courseNumber,
  courseTitle,
  courseObjective,
  targetGroup,
  dateAndDuration,
) {
  return {
    courseNumber,
    courseTitle,
    courseObjective,
    targetGroup,
    dateAndDuration,
  };
}

const rows = [
  createData(
    1,
    "Forest Fire Prevention Techniques",
    "Practical skills on: Prevention, Preparedness, Detection, Suppression, Restoration",
    "Personnel dealing with protection of forest resources bee reserves national parks game reserves trees outside forests Forest officers and Assistants",
    "One week",
  ),
  createData(
    2,
    "Entrepreneurship in Forest Based Enterprises",
    "Practical skills on: Market analysis and development, Value Addition on forest-based products",
    "The course is designed for officials from Government (especially TAMISEMI TFS) privates’ sector business owners and others who are interested with entrepreneurial knowledge and skills on forest based enterprises.",
    "One week",
  ),
  createData(
    3,
    "Tree Nursery establishment and management",
    "Practical skills on: Tree nursery establishment and management. Techniques of raising tree seedlings and nursery management.",
    "The course is designed for those working and dealing with conservation afforestation and reforestation projects in both public and private sectors including forest plantations and reserves NGOs and tree growers.",
    "Two weeks",
  ),
  createData(
    4,
    "Project Monitoring and Evaluation (M&E)",
    "Practical skills on: Monitoring and evaluation of development projects. Designing and implementation of M&E tools.",
    "The course is designed for those working and dealing with projects in forestry and other natural resources government and non-government organizations",
    "Two weeks",
  ),
  createData(
    5,
    "Remote Sensing and GIS application in forest resources management",
    "Practical skills on: Spatial data handling acquisition processing management Techniques on forest resources mapping.",
    "The course is designed for those working and dealing with projects in forestry and other natural resources project coordinators in government organizations NGOs and private companies.",
    "Two weeks",
  ),
  createData(
    6,
    "Forest Resource Assessment for Participatory Forest Management Planning",
    "Practical skills on: Design and planning Participatory Forest Resources Assessment (PFRA) for Participatory Forest Management (PFM)",
    "Practitioners managers and facilitators who work on agriculture livestock forestry wildlife community development NGOs local communities and private sectors",
    "Two weeks",
  ),
  createData(
    7,
    "Plant identification and herbarium management techniques.",
    "Practical skills on: Plants identification techniques in various vegetations. Herbarium management",
    "The course is designed and suitable for those working in Nature reserves Catchment forests and gardening landscaping herbarium and tour guiding.",
    "Two weeks",
  ),
  createData(
    8,
    "Best practices for improved Forest law enforcement",
    "Practical skills on: Forest laws and compliance Best practice for forest law enforcement and compliance evaluation",
    "Practitioners managers and facilitators who work on forest surveillance and check points forest products business and entrepreneurs NGOs and government and private sectors.",
    "Two weeks",
  ),
  createData(
    9,
    "Diversified Agroforestry Practices for Livelihood Security",
    "Practical skills on: Agroforestry practices for livelihood security Role of agroforestry in achieving sustainable forest management (SFM).",
    "Extension workers that deal with projects advocating multipurpose land uses to foster both conservation of forest resources and sustainable livelihoods. Individual farmers that intend to intensify and diversify their land production for increased income.",
    "Two weeks",
  ),
  createData(
    10,
    "Silvicultural Operations in Management of Plantation forests",
    "Practical skills on: Silvicultural operations for the best performance of plantation forests.",
    "The course is designed for those working and dealing afforestation and reforestation projects in both public and private sectors including forest plantations and reserves NGOs and tree growers and vendors.",
    "One week",
  ),
  createData(
    11,
    "Apiary and honeybee management",
    "Practical skills on: Apiary and honeybee Management for improved honeybee production",
    "Workers in organizations and Private stakeholders and farmers dealing with beekeeping activities and management. The course is also suitable for individuals interested in gaining knowledge and skills in beekeeping industry.",
    "Two weeks",
  ),
  createData(
    12,
    "Techniques for establishment and management of Forest Plantation and woodlots",
    "Practical skills on: Forest plantation and woodlot establishment. Management of forest plantation and woodlot",
    "The course is designed for those working and dealing with afforestation and reforestation projects in both public and private sectors including forest plantations and reserves NGOs and tree out growers and vendors.",
    "One week",
  ),
  createData(
    13,
    "Honey bee ecology and conservation",
    "Practical skills on: Honey bee ecology Bee fodder plant identification and conservation Fire wise application in beekeeping operations",
    "The course is designed for those working and dealing with beekeeping conservation afforestation and reforestation projects in both public and private sectors including Natural forests and bee reserves and beekeeping industry",
    "Two weeks",
  ),
  createData(
    14,
    "Tree seed collection and handling techniques",
    "Practical skills on: Tree seed collection Tree seed extraction Tree seed handling and storage",
    "Practitioners managers and facilitators who work on tree seed technology forest establishment tree nursery Tanzania tree seed agency tree seed business and tree seed collectors",
    "One week",
  ),
  createData(
    15,
    "Eco-tourism entrepreneurship design and best practices",
    "Practical skills on: Developing Eco- tourism business for Sustainable Forest Management (SFM).",
    "The course is designed for those working in natural forests (Nature reserves Catchment forests) eco- tourism and tourism business or wishing to work in eco-tourism enterprise and tour guiding.",
    "Two weeks",
  ),
  createData(
    16,
    "Ecology and Conservation Biology",
    "Practical skills on: Ecological analysis Biodiversity management Techniques for biodiversity conservation",
    "The course is designed for those working in natural forests (Nature reserves Catchment forests) eco- tourism and tourism business or wishing to work ecology and conservation activities.",
    "One week",
  ),
  createData(
    17,
    "Project Design Planning and Management",
    "Knowledge and skills on Ways of designing and implementing feasible projects.",
    "The course is designed for those working and dealing with projects and project planning and management in forestry and other natural resources government and non-government organizations",
  ),
];

export default function ShortCourse() {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table className="table table-responsive table-bordered table-striped border-dark table-hover">
          <TableHead
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              textAlign: "center",
            }}
          >
            <TableRow
              style={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                textAlign: "center",
              }}
            >
              <StyledTableCell
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                No
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Course Name
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Course Objective
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Target Group
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Duration&nbsp;(Weeks)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.programs}>
                  <StyledTableCell component="th" scope="row">
                    {row.courseNumber}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      textAlign: "justify",
                    }}
                  >
                    {row.courseTitle}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      textAlign: "justify",
                    }}
                  >
                    {row.courseObjective}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      textAlign: "justify",
                    }}
                  >
                    {row.targetGroup}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      textAlign: "justify",
                    }}
                  >
                    {row.dateAndDuration}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={5} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}
