import React, { Component } from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/system";

const YellowListItemIcon = styled(ListItemIcon)({
  color: "gold",
});

class Announcements extends Component {
  render() {
    /* sidebar announcements*/

    let categoryData = [
      {
        categoryLink:
          "https://www.fti.ac.tz/uploads/SELECTED_STUDENTS_2024_2025.pdf",
        categoryName: "LIST OF SELECTED STUDENTS FOR ACADEMIC YEAR 2024/2025",
      },
      {
        categoryLink: "https://saris.maliasili.go.tz/Registration/20",
        categoryName: "Apply for Academic Year  2024/2025",
      },
      {
        categoryLink:
          "https://www.fti.ac.tz/uploads/tamisemi-selected-fti-2024-25.pdf",
        categoryName:
          "A List of Selected Students by PO-RALG  (TAMISEMI) for the Academic Year 2024/2025",
      },
    ];

    return (
      <div>
        <div className="sidebar-wrapper">
          <div className="sidebar">
            <h3 className="sidebar-title">Announcements</h3>
            <List className="sidebar-list">
              {categoryData.map((item, index) => (
                <ListItem
                  key={index}
                  component="a"
                  href={item.categoryLink}
                  target="_blank"
                >
                  <YellowListItemIcon>
                    <CampaignIcon fontSize="large" />
                  </YellowListItemIcon>
                  <ListItemText primary={item.categoryName} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default Announcements;
