import React, { Component } from "react";
import ServiceGridSlider from "../components/ServiceGridSlider";
import PrincipalStatement from "../components/PrincipalStatement";
import BlogGrid from "../components/BlogGrid";
import BrandLogoSlider from "../components/BrandLogoSlider";
import HeroSliderOne from "../components/HeroSliderOne";
import Marquees from "../components/Marquees.js";
import Vision from "../pages/Vision.js";
class Home extends Component {
  render() {
    return (
      <div>
        <Marquees />

        <HeroSliderOne />

        <PrincipalStatement />

        <Vision />

        <BrandLogoSlider />

        <BlogGrid background="grey-bg" />

        <ServiceGridSlider />
      </div>
    );
  }
}

export default Home;
